'use client'

interface ErrorProps {
  error: Error
  reset: () => void
}

const Error = ({}: ErrorProps) => {
  return <div>Ops</div>
}

export default Error
